<template>
  <div>
    <CContainer>
      <CRow>
        <CCol md="6">
          <CCardGroup>
            <CCard>
              <CCardHeader>
                <strong v-if="$route.params.id">
                  Редактирование пользователя
                </strong>
                <strong v-else> Создание пользователя </strong>
              </CCardHeader>
              <CCardBody>
                <CForm @submit.prevent="handleSubmit">
                  <CInput v-model="name" label="Имя (показывается в КП заказчику)" placeholder=""> </CInput>
                  <CInput v-model="email" label="Почта (показывается в КП заказчику)" placeholder=""></CInput>
                  <CInput id="phone" v-model="phone" label="Телефон (показывается в КП заказчику)" placeholder="+0 000 000 00 00"></CInput>
                  <CInput
                    v-if="$route.params.id"
                    v-model="password"
                    label="Новый пароль*"
                    placeholder=""
                  >
                  </CInput>
                  <CInput
                    v-else
                    v-model="password"
                    label="Пароль*"
                    placeholder=""
                  >
                  </CInput>
                  <p class="mb-20 mt-20" style="font-size: 11px">
                    * — Пароль нужно скопировать и скинуть пользователю, он НЕ
                    отсылается по почте
                  </p>
                  <CButton
                    color="dark"
                    type="button"
                    @click="generateRandomPassword"
                  >
                    Сгенерировать случайный
                  </CButton>
                  <br />
                  <br />
                  <CSelect
                    label="Роль"
                    :options="groups"
                    :value.sync="group"
                  />
                  <br />
                  <br />
                  <CRow class="mb-30">
                    <CCol sm="4">
                      <label>Доступны все заказы (свои и чужие)</label>
                    </CCol>
                    <CCol sm="6">
                      <input
                        type="checkbox"
                        v-model="is_superuser"
                      />
                    </CCol>
                  </CRow>
                  <br />
                  <br />
                  <CRow>
                    <CCol v-if="$route.params.id">
                      <div
                        class="
                          d-grid
                          gap-2
                          d-md-flex
                          justify-content-md-between
                        "
                      >
                        <CButton
                          color="success"
                          type="submit"
                          @click="handleBack"
                        >
                          Сохранить
                        </CButton>
                        <CButton
                          color="danger"
                          type="submit"
                          @click="handleNotActive"
                        >
                          Удалить
                        </CButton>
                      </div>
                    </CCol>
                    <CCol v-else>
                      <CButton color="success" type="submit"> Создать </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import groups from "./groups";
import { mask } from "../../_helpers/mask"

export default {
  name: "UserForm",
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      password: "",
      is_active: true,
      is_superuser: false,
      group: 1,
      groups,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.users.all.user,
    }),
  },
  created() {
    if (this.$route.params.id) {
      this.getById(this.$route.params.id);
    }
  },
  mounted() {
    mask("#phone")
  },
  methods: {
    ...mapActions("users", ["create", "update", "getById"]),
    generatePassword() {
      var length = 8,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    },

    handleBack() {
      history.back();
    },

    handleNotActive() {
      this.is_active = false;
      this.handleSubmit();
      this.handleBack();
    },
    generateRandomPassword() {
      this.password = this.generatePassword();
    },
    handleSubmit() {
      let formData = {
        first_name: this.name,
        email: this.email,
        phone: this.phone,
        groups: [this.group],
        is_active: this.is_active,
        is_superuser: this.is_superuser,
      };
      if (this.password) {
        formData.password = this.password;
      }

      if (this.$route.params.id) {
        formData.id = this.$route.params.id;
        this.update(formData);
      } else {
        this.create(formData);
      }
    },
  },
  watch: {
    user: function (val, oldVal) {
      if (val) {
        this.name = val.first_name;
        this.email = val.email;
        this.phone = val.phone;
        this.group = val.groups[0];
        this.is_active = val.is_active;
        this.is_superuser = val.is_superuser;
      }
    },
  },
};
</script>
