const groups =
[  
  {
    value: 1,
    label: "Менеджер",
  },
  {
    value: 2,
    label: "Администратор",
  },
]

export default groups 


